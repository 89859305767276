<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>盘点</span>
            </div>

            <!-- 盘点-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToStockCheck"
                            class="font1"
                            :disabled="!hasStockCheckPrivilege"
                        >
                            盘点
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateStockCheck"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            可新建盘点单
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewStockCheck"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核盘点单</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockCheck"
                            :disabled="!hasCreateStockCheckPrivilege"
                            >①新建盘点单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择机构名称--选择摘要-点击选择商品基本信息-选择需要盘点的商品-点击确定-填写实际盘点出的数量-点击保存
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockCheck"
                            :disabled="!hasReviewStockCheckPrivilege"
                        >
                            ②审核盘点单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的内购单-点击审核-审核成功后自动生成盘点差异单</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockCheckDiff"
                            :disabled="!hasStockCheckDiffPrivilege"
                        >
                            ③查看盘点差异单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span
                            >审核通过的盘点单会自动生成盘点差异单，可根据盘点差异单自建报损/内购等单据处理库存差异</span
                        >
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockCheck"
                            :disabled="!hasReviewStockCheckPrivilege"
                        >
                            ④盘点数量不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现盘点数量有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核
                            -确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核盘点单操作(反审核后盘点差异单自动取消)
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const stockCheckPrivilegeFlag = 'menu.stock.check';
const openStockCheckPrivilegeFlag = `${stockCheckPrivilegeFlag}.open`;
const createStockCheckPrivilegeFlag = `${stockCheckPrivilegeFlag}.create`;
const reviewStockCheckPrivilegeFlag = `${stockCheckPrivilegeFlag}.review`;

const stockCheckDiffPrivilegeFlag = 'menu.stock.checkDiff';
const openStockCheckDiffPrivilegeFlag = `${stockCheckDiffPrivilegeFlag}.open`;
export default {
    name: 'StockCheckGuide',
    data() {
        return {
            rolesForCreateStockCheck: [],
            rolesForReviewStockCheck: [],
        };
    },
    created() {
        //查询"能够创建盘点单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createStockCheckPrivilegeFlag).then((rst) => {
            this.rolesForCreateStockCheck = rst;
        });
        //查询"能够审核盘点单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewStockCheckPrivilegeFlag).then((rst) => {
            this.rolesForReviewStockCheck = rst;
        });
    },
    computed: {
        roleNamesForCreateStockCheck() {
            return this.rolesForCreateStockCheck.map((e) => e.name).join('，');
        },
        roleNamesForReviewStockCheck() {
            return this.rolesForReviewStockCheck.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToStockCheck() {
            Util.nameJump(this, stockCheckPrivilegeFlag);
        },
        jumpToStockCheckDiff() {
            Util.nameJump(this, stockCheckDiffPrivilegeFlag);
        },

        hasStockCheckPrivilege() {
            return this.hasPrivilege(openStockCheckPrivilegeFlag);
        },
        hasCreateStockCheckPrivilege() {
            return this.hasPrivilege(createStockCheckPrivilegeFlag);
        },
        hasReviewStockCheckPrivilege() {
            return this.hasPrivilege(reviewStockCheckPrivilegeFlag);
        },
        hasStockCheckDiffPrivilege() {
            return this.hasPrivilege(openStockCheckDiffPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
